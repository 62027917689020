import React, {useContext, useEffect, useState } from "react";
import { Box, chakra, Icon, Image, useColorModeValue } from "@chakra-ui/react";
import { isMobile } from "react-device-detect";
import i18next from "../../Utils/i18n";

export default function DondeEstamos() {
  const bg = useColorModeValue("white", "gray.800");

  return (
    <Box pos="relative" overflow="hidden" shadow="lg" rounded="lg" border={"1px solid"} borderColor={"gray.300"} bg={bg} mt={10} marginLeft={isMobile ? "" : "120px"} marginRight={isMobile ? "" : "120px"}>
      <Box position={{lg: "absolute"}} top={{lg: 0}} bottom={{lg: 0}} right={{lg: 0}} w={{lg: "50%"}} border="solid 1px transparent">
        <Image h={[56, 72, 96, "full"]} w="full" fit="cover" src={isMobile ? "../../../assets/Donde_Mobile.jpg" : "../../../assets/Donde_Web.jpg" } alt="" loading="lazy" />
      </Box>
      <Box maxW="7xl" mx="auto" >
        <Box pos="relative" pb={{base: 8, sm: 16, md: 20, lg: 28, xl: 32}} maxW={{lg: "2xl"}} w={{lg: "full"}} zIndex={1} bg={bg} border="solid 1px transparent" style={{paddingBottom: "25px"}}>
          <Icon display={{base: "none", lg: "block"}} position="absolute" right={0} top={0} bottom={0} h="full" w={48} color={bg} transform="translateX(50%)" fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
            <polygon points="50,0 100,0 50,100 0,100" />
          </Icon>
          <Box mx="auto" maxW={{base: "7xl"}} px={{base: 4, sm: 6, lg: 8}} mt={{base: 10, sm: 12, md: 16, lg: 20, xl: 28}} style={{marginTop: "10px"}}>
            <Box w="full" textAlign={{sm: "center", lg: "left"}} justifyContent="center" alignItems="center">
              <chakra.h1 fontSize={{base: "4xl", sm: "5xl", md: "6xl"}} letterSpacing="tight" lineHeight="short" fontWeight="extrabold" color={useColorModeValue("gray.900", "white")}>
                <chakra.span display={{base: "block", xl: "inline"}}>
                  {i18next.t('Where.Find')}
                </chakra.span>
              </chakra.h1>
              <chakra.p mt={{base: 3, sm: 5, md: 5}} fontSize={{sm: "lg", md: "xl"}} maxW={{sm: "xl"}} mx={{sm: "auto", lg: 0}} color="gray.500">
                {i18next.t('Where.FindOnMap')}
              </chakra.p>
              <Box mt={{base: 5, sm: 8}} display={{sm: "flex"}} justifyContent={{sm: "center", lg: "start"}} fontWeight="extrabold">
                <Box rounded="full" shadow="md">
                  <chakra.a w="full" display="flex" alignItems="center" justifyContent="center" border="solid 1px transparent" fontSize={{base: "md", md: "lg"}} rounded="md" color="white" bg="black" _hover={{bg: "brand.700"}} px={{base: 8, md: 10}} py={{base: 3, md: 4}} cursor="pointer" href="/donde-estamos" className="btnHome">
                    {i18next.t('Where.Button')}
                  </chakra.a>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
