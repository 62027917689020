import React, { useContext, useEffect, useState } from "react";
import { ChakraProvider } from "@chakra-ui/react";
import Header from "./Component/Header/Header";
import { Footer } from "./Component/Footer/Footer";
import { Outlet } from "react-router-dom";
// import { doLogin, getColabs, getFamilies } from "./Api/Api";
// import { AppContext } from ".";
// import { SET_COLABS, SET_FAMILIES } from "./Reducers/AppReducer";
// import TagManager from "react-gtm-module";

import "./App.css";
import Maintenance from "./Pages/Maintenance/Maintenance";

function App() {
  // const { dispatch } = useContext(AppContext);
  // const tagManagerArgs = {
  //   gtmId: "GTM-K3NQC7P",
  // };
  useEffect(() => {
    // TagManager.initialize(tagManagerArgs);
    // postLogin();
    // initFamilies();
    // initColabs();
  }, []);

  // const initFamilies = () => {
  //   getFamilies().then((response) => {
  //     dispatch({ type: SET_FAMILIES, families: response.data });
  //   });
  // };

  // const initColabs = () => {
  //   getColabs().then((response) =>
  //     dispatch({ type: SET_COLABS, colabs: response.data })
  //   );
  // };

  // const postLogin = () => {
  //   return doLogin();
  // };

  const cookieStyles = {
    button: {
      overflow: "hidden",
      position: "absolute",
      border: "none",
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      background: "transparent",
      color: "#1da1f2",
      textIndent: "100%",
      cursor: "pointer",
      top: "15px",
      right: "25px",
      fontFamily: "monospace",
      fontSize: "1.3em",
      padding: "0px 10px",
      color: "white",
    },
  };
  const isMaintenanceMode = true;
  return (
    <>
      {" "}
      {isMaintenanceMode ? (
        <Maintenance />
      ) : (
        <ChakraProvider>
          <Header></Header>
          <Outlet></Outlet>
          <Footer></Footer>
        </ChakraProvider>
      )}
    </>
  );
}

export default App;
