import React from "react";
import "./Maintenance.css";

const Maintenance = () => {
  return (
    <div className="maintenance-container">
      <img
        src="/assets/logo_header.png"
        alt="Logo de la empresa"
        className="maintenance-logo"
      />

      <div className="maintenance-heading">Web en mantenimiento</div>
    </div>
  );
};

export default Maintenance;
