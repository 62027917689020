import React, { useReducer } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ColorModeScript } from "@chakra-ui/react";
import theme from "./theme";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home/Home";
import Family from "./Pages/Family/Family";
import { AppReducer, INITIAL_STATE } from "./Reducers/AppReducer";
import Where from "./Pages/Where/Where";
import Legal from "./Pages/Legal/Legal";
import Privacy from "./Pages/Privacy/Privacy";
import Contact from "./Pages/Contact/Contact";
import Work from "./Pages/Work/Work";
import ClickAndCollect from "./Pages/ClickAndCollect/ClickAndCollect";
import NotFound from "./Pages/NotFound/NotFound";
import City from "./Pages/Where/City";
import Unete from "./Pages/Promos/Unete/Unete";
import PideYa from "./Pages/Promos/PideYa/PideYa";
import Thegamingfamily from "./Pages/Promos/Thegamingfamily/Thegamingfamily";
import Illojuan from "./Pages/Promos/Illojuan/Illojuan";
import Carrefour from "./Pages/Promos/Carrefour/Carrefour";
import DescargaApp from "./Pages/Promos/DescargaApp/DescargaApp";
import Ibai from "./Pages/Promos/Ibai/Ibai";
import "animate.css";
import LGFMxJET from "./Pages/Promos/LGFMxJET/LGFMxJET";

export const AppContext = React.createContext([]);

const Content = () => {
  const [state, dispatch] = useReducer(AppReducer, INITIAL_STATE);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <BrowserRouter>
        <Routes>
          <Route element={<App />}>
            <Route path="/" element={<Home />} />
            {/* <Route path="carta" element={<Family />} />
            <Route path="carta/:catName" element={<Family />} />
            <Route path="donde-estamos" element={<Where />} />
            <Route path="unete" element={<Unete />} />
            <Route path="aviso-legal" element={<Legal />} />
            <Route path="politica-privacidad" element={<Privacy />} />
            <Route path="contacto" element={<Contact />} />
            <Route path="trabaja-con-nosotros" element={<Work />} />
            <Route path="click-and-collect" element={<ClickAndCollect />} />
            <Route path="donde-estamos/:kitchenName" element={<City />} /> */}
            <Route path="*" element={<NotFound />} />
          </Route>
          {/* <Route path="pide-ya" element={<PideYa />} /> */}
          {/* <Route path="descarga-app" element={<DescargaApp />} /> */}
          {/* <Route path="thegamingfamily" element={<Thegamingfamily />} /> */}
          {/* <Route path="illojuan" element={<Illojuan />} /> */}
          {/* <Route path="cartacarrefour" element={<Carrefour />} /> */}
          {/* <Route path="ibai" element={<Ibai />} /> */}
          {/* <Route path="lgfmxjet" element={<LGFMxJET />} /> */}
         
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <Content />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
