import React, { useContext } from "react";
import { Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { AppContext } from "../..";
import { SET_CURRENT_FAMILY } from "../../Reducers/AppReducer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { isMobile } from "react-device-detect";

import "swiper/css";
import "swiper/css/navigation";

import "swiper/css/pagination";
import "./carousel.css";

/**
 * It's a function that returns a Swiper component that contains a SwiperSlide component for each slide
 * in the slides array.
 * @returns An array of objects.
 */
const Carousel = () => {
  const { state, dispatch } = useContext(AppContext);

  const slides = [
    {
      img: "/assets/Banner_App_Dsk.png",
      imgMobile: "/assets/Banner_App_Mbl.png",
      url: "/carta/the-gaming-family",
      familyId: 50,
    },
    {
      img: "/assets/Banner_Web_Dsk.png",
      imgMobile: "/assets/Banner_Web_Mbl.png",
      url: "/carta/the-gaming-family",
      familyId: 50,
    },
    {
      img: "/assets/Slider_Carrefour_Desktop.png",
      imgMobile: "/assets/Slider_Carrefour_Mobile.png",
      url: "https://promos.lagranfamiliamediterranea.com/pide-ya",
      familyId: 0,
    },
  ];

  const setFamilyId = (familyId) => {
    if (familyId > 0) dispatch({ type: SET_CURRENT_FAMILY, family: familyId });
  };

  return (
    <Swiper
      pagination={{ dynamicBullets: true }}
      navigation={true}
      modules={[Pagination, Navigation, Autoplay]}
      autoplay={{ delay: 8000, disableOnInteraction: false }}
      loop={false}
    >
      {slides.map((slide, index) => {
        return (
          <SwiperSlide key={index}>
            {slide.url.startsWith("https") ? (
              <a
                href={slide.url}
                target="_blank"
                onClick={() => setFamilyId(slide.familyId)}
              >
                <Image
                  src={isMobile ? slide.imgMobile : slide.img}
                  w="full"
                  backgroundSize="cover"
                />
              </a>
            ) : (
              <Link to={slide.url} onClick={() => setFamilyId(slide.familyId)}>
                <Image
                  src={isMobile ? slide.imgMobile : slide.img}
                  w="full"
                  backgroundSize="cover"
                />
              </Link>
            )}
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default Carousel;
