import { HStack, Image, Text } from "@chakra-ui/react";
import * as React from "react";
import { isMobile } from "react-device-detect";

export const Copyright = () => (
  <div style={{zIndex: '2'}}>
    <HStack>
      <a href="https://play.google.com/store/apps/details?id=com.gdg.lgfm"><Image src="/assets/Badge_Google.png" alt="Google Play y el logotipo de Google Play son marcas comerciales de Google LLC. atl de la imagen" /></a>
      <a href="https://lagranfamiliamediterranea.com/apple"><Image src="/assets/Badge_Apple.png" /></a>
    </HStack>
  </div>
);
