export const SET_CURRENT_FAMILY = 'SET_CURRENT_FAMILY';
export const SET_FAMILIES = 'SET_FAMILIES';
export const SET_COLABS = 'SET_COLABS';

export const INITIAL_STATE = {
    current_family: "",
    families: {},
    colabs: {}
}

export const AppReducer = (state, action) => {
    switch(action.type) {
        case SET_CURRENT_FAMILY:
            return {
                ...state,
                current_family: action.family
            }
        case SET_FAMILIES:
            return {
                ...state,
                families: action.families
            }
        case SET_COLABS:
            return {
                ...state,
                colabs: action.colabs
            }
        default:
            return state;
    }
}