import axios from "axios";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue, get, child } from "firebase/database";
// const utf8 = require("utf8");
export const Api = axios.create({
  // baseURL: "https://lgfm-backend-proxy.de-c1.cloudhub.io",
  // baseURL: "https://lgfm-backend-proxy-sandbox.de-c1.cloudhub.io",
  baseURL: "https://lgfm.app/",
  //   baseURL: "https://pre.delivery-platf.lgfm.app/",

  timeout: 100000,
  headers: {
    "Content-Type": "application/json",
    // "Access-Control-Allow-Origin": "*",
  },
});

const config = {
  apiKey: "AIzaSyAexB1m7KArW-VuCOzJc6V--4vSpT47pSU",
  authDomain: "web-lgfm.firebaseapp.com",
  databaseURL:
    "https://web-lgfm-default-rtdb.europe-west1.firebasedatabase.app",
};

const app = initializeApp(config);
const dbRef = ref(getDatabase(app));

export const getFamilies = () => {
  return Api({
    method: "GET",
    url: "/api/v01/DGGFM/es/mvp/families/",
  });
  // return get(child(dbRef, 'families'));
};

export const getColabs = () => {
  return Api({
    method: "get",
    url: "/api/v01/DGGFM/es/mvp/collaborations/",
  });
};

export const doLogin = () => {
  Api({
    method: "post",
    url: "/api/v01/DGGFM/es/login/",
    data: {
      username: "nacho.porras@lgfm.app",
      password: "A123qwe_",
    },
  }).then((response) => {
    return response;
  });
};

export const getProduct = (productId) => {
  return Api({
    method: "get",
    url: "/api/v01/DGGFM/es/products/" + productId + "/",
  });
};

export const getFamilyProducts = (categoryId) => {
  return Api({
    method: "get",
    url: "/api/v01/DGGFM/es/products/",
    params: {
      category_id: categoryId,
    },
  });
  // return get(child(dbRef, 'products_' + categoryId));
};

export const getProductsCRF = () => {
  return Api({
    method: "get",
    url: "/api/v01/CRFSS/es/products/",
    params: {
      category_id: 165,
    },
  });
};

export const getProductCRF = (productId) => {
  return Api({
    method: "get",
    url: "/api/v01/CRFSS/es/products/" + productId + "/",
  });
};
