import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { Box, chakra, Image, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
import ReactLoading from 'react-loading';
import { isMobile } from "react-device-detect";
import Helmet from "react-helmet";
import Carousel from "../../Component/Carousel/Carousel";
import DondeEstamos from "../../Component/DondeEstamos/DondeEstamos";
import { TopVentas } from "../../Component/TopVentas/TopVentas";
import { AppContext } from "../..";
import { SET_CURRENT_FAMILY } from "../../Reducers/AppReducer";
import { DaniFav } from "../../Component/DaniFav/DaniFav";
import i18next from "../../Utils/i18n";

import './home.css';

export default function Home() {
  const {state, dispatch} = useContext(AppContext);
  const h4Color = useColorModeValue("gray.900", "white");
  
  const setFamilyId = (familyId) => {
    dispatch({type: SET_CURRENT_FAMILY, family: familyId});
  }

  return (
    <Box px="25" pt={"100px"}>
      <Helmet>
          <link rel="canonical" href={window.location.origin + window.location.pathname} />
      </Helmet>
      <Carousel></Carousel>
      <Box my="15px">
        <chakra.h4 fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }} letterSpacing="tight" lineHeight="short" fontWeight="extrabold" textAlign="center" color={h4Color}>
          <chakra.span display={{ base: "block", xl: "inline" }}>
            {i18next.t('Home.Meet')}
          </chakra.span>
        </chakra.h4>
      </Box>

      {(Object.keys(state.families).length > 0) ? (
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 3 }} spacing={10} px={25} paddingLeft={isMobile ? "" : "120px"} paddingRight={isMobile ? "" : "120px"}>
          {
            Object.keys(state.families).length > 0 && state.families.map(family => 
              <Link to={"/carta/" + family.slug} key={family.id}>
                <Image h="auto" objectFit="contain" src={family.horizontal_image} alt={family.name_es} borderRadius={"25px"} shadow="lg" onClick={() => setFamilyId(family.slug)} className="catImg" />
              </Link>
            )
          }
        </SimpleGrid>
      ) : <Box display="flex" justifyContent="center"><ReactLoading type="spin" color="#000"></ReactLoading></Box>}

      {Object.keys(state.colabs).length > 0 &&
      (
        <>
          <Box my="30px">
            <chakra.h4 fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }} letterSpacing="tight" lineHeight="short" fontWeight="extrabold" textAlign="center" color={h4Color}>
              <chakra.span display={{ base: "block", xl: "inline" }}>
                {i18next.t('Home.Collaborations')}
              </chakra.span>
            </chakra.h4>
          </Box>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 3 }} justifyItems="center" spacing={10} px={25} paddingLeft={isMobile ? "" : "120px"} paddingRight={isMobile ? "" : "120px"}>
            {
              state.colabs.map(colab => 
                <Link to={"/carta/" + colab.slug} key={colab.id}>
                  {/* <Image h="auto" objectFit="contain" src={colab.horizontal_image} alt={colab.name_es} onClick={() => setFamilyId(colab.id)} className="catImg" style={{maxWidth: isMobile ? "100px": "230px"}} /> */}
                  <Image h="auto" objectFit="contain" src={colab.horizontal_image} alt={colab.name_es} borderRadius={"25px"} shadow="lg" onClick={() => setFamilyId(colab.id)} className="catImg" />
                </Link>
              )
            }
          </SimpleGrid>
        </>
      )}
      <Box my="30px">
        <chakra.h4 fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }} letterSpacing="tight" lineHeight="short" fontWeight="extrabold" textAlign="center" color={h4Color}>
          <chakra.span display={{ base: "block", xl: "inline" }}>
            {i18next.t('Home.Topseller')}
          </chakra.span>
        </chakra.h4>
      </Box>
      {/* <TopVentas></TopVentas> */}
      <DondeEstamos></DondeEstamos>
      <Box marginTop="15px"></Box>
      {/* <Promo></Promo> */}
      {/* <Box my="15px">
        <chakra.h4 fontSize={{base: "2xl", sm: "3xl", md: "4xl"}} letterSpacing="tight" lineHeight="short" fontWeight="extrabold" textAlign="center" color={h4Color}>
          <chakra.span display={{base: "block", xl: "inline"}}>
            Dani & Friends
          </chakra.span>
        </chakra.h4>
      </Box>
      <DaniFav></DaniFav> */}
    </Box>
  );
}
