import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import es from './languages/es.json';
import en from './languages/en.json';

i18next.use(LanguageDetector).init({
    resources: {
        en: {
            translation: en
        },
        es: {
            translation: es
        }
    },
    fallbackLng: "es",
    interpolation: {
        escapeValue: false
    }
});

export default i18next;