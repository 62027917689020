import { Box, ButtonGroup, IconButton, Link } from "@chakra-ui/react";
import * as React from "react";
import { isMobile } from "react-device-detect";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa";
import i18next from "../../Utils/i18n";

import "./footer.css";

export const SocialMediaLinks = (props) => (
  <Box align="center" w="full" spacing={4} justifyContent={"center"} style={{position: isMobile ? "" : "absolute", width: isMobile ? "" : "92%", marginRight: isMobile ? "" : "30px"}}>
    <Box>
      <Link href="/politica-privacidad" style={{marginTop: "8px", marginRight: "10px"}}>
        {i18next.t('Footer.Privacy')}
      </Link>
      <Link href="/aviso-legal" style={{marginTop: "8px", marginRight: "10px"}}>
        {i18next.t('Footer.Legal')}
      </Link>
      <Link href="/trabaja-con-nosotros" style={{marginTop: "8px", marginRight: "10px"}}>
        {i18next.t('Footer.WorkWithUs')}
      </Link>
      <Link href="/assets/alergenos_lgfm.pdf" style={{marginTop: "8px", marginRight: "10px"}}>
        {i18next.t('Footer.AllergensMenu')}
      </Link>
    </Box>
    <ButtonGroup variant="ghost" color="white" {...props}>
      <IconButton as="a" className="social-button" href="https://es.linkedin.com/company/la-gran-familia-mediterr%C3%A1nea" target="_blank" aria-label="LinkedIn" icon={<FaLinkedin fontSize="20px" />} />
      <IconButton as="a" className="social-button" href="https://www.instagram.com/lagranfamiliamediterranea/" target="_blank" aria-label="Instagram" icon={<FaInstagram fontSize="20px" />} />
      <IconButton as="a" className="social-button" href="https://www.facebook.com/lagranfamiliamediterranea/" target="_blank" aria-label="Facebook" icon={<FaFacebook fontSize="20px" />} />
    </ButtonGroup>
    <Box>&copy; {new Date().getFullYear()} GRUPO DANI GARCÍA</Box>
  </Box>
);
